const appConfig = {
  isDevelopment: process.env.NODE_ENV === 'development',
  isTest: process.env.NODE_ENV === 'test',
  isProduction: process.env.NODE_ENV === 'production',
  iasApiDomain: process.env.REACT_APP_IAS_API_DOMAIN,
  emspApiDomain: process.env.REACT_APP_EMSP_API_DOMAIN,
  emspApiDomainV2: process.env.REACT_APP_EMSP_API_DOMAIN_V2,
  emspApiDomainV3: process.env.REACT_APP_EMSP_API_DOMAIN_V3,
  emspApiDomainV4: process.env.REACT_APP_EMSP_API_DOMAIN_V4,
  secureStorageSecretKey: process.env.REACT_APP_SECURE_STORAGE_SECRET_KEY,
  paymentGatewayKey: process.env.REACT_APP_PAYMENT_GATEWAY_KEY,
  titleSuffix: process.env.REACT_APP_TITLE_SUFFIX,
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  },
};

export default appConfig;
